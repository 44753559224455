<template>
  <div class="app-container">
    <div class="header">
      <p class="title">摄像头信息</p>
      <div class="header-info">
        <div class="left">
          <div>
            <label>摄像头编号：</label>
            {{ cameraInfo.cameraid }}
          </div>
          <div>
            <label>摄像头名称：</label>
            {{ cameraInfo.cameraNo }}
          </div>
          <div>
            <label>MacID:</label>
            {{ cameraInfo.macId }}
          </div>
        </div>
        <div class="right">
          <el-image style="width: 140px" :src="cameraInfo.bkImgUrl" alt="" :previewSrcList="[cameraInfo.bkImgUrl]" />
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="config">
      <div class="title">摄像头主码流配置</div>
      <div class="config-item">
        <label>分辨率:</label>
        {{ cameraInfo.mainVideo?.resolution }}
      </div>
      <div class="config-item">
        <label>图像质量:</label>
        {{ cameraInfo.mainVideo?.videoQuality }}
      </div>
      <div class="config-item">
        <label>视频编码:</label>
        {{ cameraInfo.mainVideo?.encode }}
      </div>
      <div class="config-item">
        <label>关键帧间隔:</label>
        {{ cameraInfo.mainVideo?.keyFrameInterval }}
      </div>
      <div class="config-item">
        <label>帧率:</label>
        {{ cameraInfo.mainVideo?.frameRate }}{{ cameraInfo.mainVideo?.frameRate ? 'fps' : '' }}
      </div>
      <div class="config-item">
        <label>码流上限:</label>
        {{ cameraInfo.mainVideo?.dataRateUpperLimit }}{{ cameraInfo.mainVideo?.dataRateUpperLimit ? 'Kbps' : '' }}
      </div>
      <div class="config-item">
        <label>码流类型:</label>
        {{ cameraInfo.mainVideo?.fpsType }}
      </div>
    </div>
    <div class="setting">
      <el-form :inline="false">
        <dl>
          <dt>
            <el-form-item label="硬编解码:">
              <el-switch v-model="cameraInfo.accelerateHardware" />
            </el-form-item>
          </dt>
          <dd v-show="cameraInfo.accelerateHardware">
            <el-form-item label="分辨率:">
              {{ cameraInfo.hardVideo?.resolution }}
            </el-form-item>
            <el-form-item label="帧率:"> {{ cameraInfo.hardVideo?.frameRate }}fps </el-form-item>
            <el-form-item label="营业时间码率:">
              <el-select v-model="cameraInfo.hardVideo.dataRateUpperLimit">
                <el-option
                  v-for="item in bitrateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="非营业时间码率:">
              <el-select v-model="cameraInfo.hardVideo.closedtimeBitrate">
                <el-option
                  v-for="item in closedtimeBitrateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </dd>
        </dl>
      </el-form>
    </div>

    <div class="footer">
      <el-button @click="back">上一步</el-button>
      <el-button type="primary" @click="handleSave">保存</el-button>
    </div>
  </div>
</template>

<script>
import { getMngInfo, updateHardConfig } from 'src/api/product'
import { bitrateOptions, floorOptions, closedtimeBitrateOptions } from 'src/utils/selectOptions'

export default {
  data() {
    return {
      bitrateOptions,
      closedtimeBitrateOptions,
      cameraInfo: {},
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      const { cameraid: cameraId } = this.$route.query
      const params = {
        cameraId,
      }
      getMngInfo(params).then((res) => {
        console.log(res)
        this.cameraInfo = res.data
      })
    },
    back() {
      this.$router.go(-1)
    },
    handleSave() {
      const floorNumStr = floorOptions.find((item) => item.value === this.cameraInfo.floorNum).label
      const bitrateStr = bitrateOptions.find(
        (item) => item.value === this.cameraInfo.hardVideo.dataRateUpperLimit
      ).label
      const closedtimeBitrateLabel = closedtimeBitrateOptions.find(
        (item) => item.value === this.cameraInfo.hardVideo.closedtimeBitrate
      )?.label

      const html = `
        <p>摄像头信息更改为以下内容：</p>
        <p>硬编解码：${this.cameraInfo.accelerateHardware ? '开启' : '关闭'}</p>
        ${
          this.cameraInfo.accelerateHardware
            ? `
            <p>帧率：${this.cameraInfo.hardVideo?.frameRate}fps</p>
            <p>分辨率：${this.cameraInfo.hardVideo?.resolution}</p>
            <p>营业时间码率：${bitrateStr}</p>
            <p>非营业时间码率：${closedtimeBitrateLabel}</p>
          `
            : ''
        }
        <p>摄像头所属楼层/区域：${floorNumStr}</p>
      `
      this.$confirm(html, '确定保存吗？', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.handleSaveConfig()
      })
    },
    handleSaveConfig() {
      const { cameraid: cameraId } = this.$route.query
      const params = {
        cameraId,
        enabled: this.cameraInfo.accelerateHardware,
        dataRateUpperLimit: this.cameraInfo.hardVideo.dataRateUpperLimit,
        closedtimeBitrate: this.cameraInfo.hardVideo.closedtimeBitrate,
      }
      updateHardConfig(params).then((res) => {
        if (res.status === 0) {
          this.$message.success('保存成功')
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
dl,
dt,
dd {
  margin: revert;
}
.app-container {
  min-height: calc(100vh - 188px);
  position: relative;
  padding-bottom: 48px;

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .header-info {
    display: flex;

    .left {
      div {
        margin-bottom: 10px;
      }
    }

    .right {
      margin-left: 20px;
    }
  }

  .config {
    .config-item {
      display: inline-block;
      margin-right: 30px;
    }
  }

  .setting {
    margin-top: 20px;

    /deep/ .el-form-item {
      margin-right: 30px;
    }
  }

  .footer {
    position: absolute;
    bottom: 15px;
  }
}
</style>
